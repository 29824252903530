// const BASE_URL = "http://localhost:3000/api";
const BASE_URL = "https://sjhcg-nurse.vercel.app/api";

export const fetchRequests = async (patientId: string) => {
  // fetch patient requests from api
  const response = await fetch(`${BASE_URL}/request?patientId=${patientId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const fetchRequestTypes = async (departmentId: string) => {
  const response = await fetch(`${BASE_URL}/requestType/${departmentId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data.sort((a: any, b: any) => a.priority - b.priority);
};

export const fetchBedInformation = async (bedId: string) => {
  const response = await fetch(`${BASE_URL}/bed?id=${bedId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const createRequest = async (
  departmentId: string,
  requestType: string,
  detailMisc: string,
  patientId: string
) => {
  const response = await fetch(`${BASE_URL}/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      departmentId: departmentId,
      requestType: requestType,
      detailMisc: detailMisc,
      patientId: patientId,
    }),
  });
  return response;
};

export const createPatient = async (room: string, bed: string) => {
  const response = await fetch(`${BASE_URL}/patient`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      room,
      bed,
    }),
  });
  const data = await response.json();
  return data.id;
};

export const deleteRequest = async (requestId: string) => {
  const response = await fetch(`${BASE_URL}/request/${requestId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedByPatient: true,
    }),
  });
  const data = await response.json();
  return data;
};
