import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Button,
  PermissionsAndroid,
  Alert,
} from "react-native";
import { BarCodeScanner } from "expo-barcode-scanner";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "./RootStackParamList";
import { Camera } from "expo-camera";
import {
  createPatient,
  fetchBedInformation,
  fetchRequestTypes,
} from "../utils/services";

// const requestCameraPermission = async () => {
//   try {
//     const granted = await PermissionsAndroid.request(
//       PermissionsAndroid.PERMISSIONS.CAMERA,
//       {
//         title: "PatientCompanion Camera Permission",
//         message:
//           "PatientCompanion needs access to your camera " +
//           "so you can have access to the hospital's services",
//         // buttonNeutral: "Ask Me Later",
//         // buttonNegative: "Cancel",
//         buttonPositive: "OK",
//       }
//     );
//     if (granted === PermissionsAndroid.RESULTS.GRANTED) {
//       console.log("You can use the camera");
//     } else {
//       console.log("Camera permission denied");
//     }
//   } catch (err) {
//     console.warn(err);
//   }
// };

type LoadingPageNavigationProp = StackNavigationProp<
  RootStackParamList, // Replace with your route param list type
  "QRCodeScanner" // Specify the name of your screen
>;

interface LoadingPageProps {
  navigation: LoadingPageNavigationProp;
}

const QRCodeScanner: React.FC<LoadingPageProps> = ({ navigation }) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };

    getBarCodeScannerPermissions();
  }, []);

  const handleBarCodeScanned = async ({ type, data }: any) => {
    setScanned(true);
    if (data === null) {
      Alert.alert("Error scanning QR code!");
      return;
    }

    // match regex
    const pattern = /[\?&]id=([^&#]*)/;
    const match = data.match(pattern);
    if (!match) {
      Alert.alert("Invalid QR code");
      return;
    }
    const [, bedId] = match;

    try {
      await AsyncStorage.setItem("bedId", bedId);
      // fetch bed/room information
      const { room, bed, departmentId } = await fetchBedInformation(bedId);
      await AsyncStorage.setItem("room", room);
      await AsyncStorage.setItem("bed", bed);
      await AsyncStorage.setItem("departmentId", departmentId);

      // create patient record
      const patientId = await createPatient(room, bed);
      await AsyncStorage.setItem("patientId", patientId);

      // fetch request types
      const requestTypes = await fetchRequestTypes(departmentId);
      await AsyncStorage.setItem("requestTypes", JSON.stringify(requestTypes));
    } catch (error) {
      console.error("ERROR in QR", error);
    }

    const room = await AsyncStorage.getItem("room");
    const bed = await AsyncStorage.getItem("bed");
    const patientLocation = `Room ${room} Bed ${bed}`;

    navigation.navigate("Home", {
      qrCodeData: patientLocation,
    });
  };

  // if (hasPermission === null) {
  //   return <Text>Requesting for camera permission</Text>;
  // }
  if (hasPermission === false) {
    // return (
    //   //   <Button title="request permissions" onPress={requestCameraPermission} />
    //   <Button title={"Tap to Scan Again"} onPress={() => setScanned(false)} />
    // );
    return <Text>No access to camera</Text>;
  }

  return (
    <View className="flex-1 flex-col">
      {scanned ? (
        <View className="flex-1 items-center justify-center">
          <Button
            title={"Tap to Scan Again"}
            onPress={() => setScanned(false)}
          />
        </View>
      ) : (
        <Camera
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          barCodeScannerSettings={{
            barCodeTypes: ["qr"],
          }}
          style={StyleSheet.absoluteFillObject}
        />
      )}
    </View>
  );
};

export default QRCodeScanner;
