import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  SafeAreaView,
  useWindowDimensions,
} from "react-native";
import Toast from "react-native-toast-message";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { RouteProp } from "@react-navigation/native";

import { BoxItemProps, ItemType, RootStackParamList } from "../utils/types";
import {
  createRequest,
  fetchRequests,
  fetchBedInformation,
  fetchRequestTypes,
} from "../utils/services";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Dropdown } from "react-native-element-dropdown";

const RequestTypeToImage: Record<string, string> = {
  Pain: "pain",
  Washroom: "washroom",
  Shower: "shower",
  "Out/In Bed": "bed",
  "Food/Drink": "food",
  "Misc Assistance": "misc",
  "Phone/TV": "phone",
};

type Props = {
  route: RouteProp<RootStackParamList, "Home">;
};

const HomePage = ({ route }: Props) => {
  const { qrCodeData } = route.params;
  const { t } = useTranslation();
  const languages = [
    { key: "English", value: "en" },
    { key: "Français", value: "fr" },
    { key: "عربي", value: "ar" },
    { key: "中文", value: "cn" },
    { key: "한국어", value: "kr" },
  ];

  const [selectedRequest, setSelectedRequest] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default to English
  const [miscData, setMiscData] = useState("");
  const [requestTypes, setRequestTypes] = useState<ItemType[]>([]);
  const [requests, setRequests] = useState<string[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [closeAppModalVisible, setCloseAppModalVisible] = useState(false);

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const navigateToViewRequest = () => {
    const userRequestIDs = requestTypes.map((request) => request.text);
    navigation.navigate("ViewRequests", { userRequestIDs });
  };

  useEffect(() => {
    const getAvailableRequestTypes = async () => {
      const bedId = await AsyncStorage.getItem("bedId");

      if (bedId) {
        const { room, bed, departmentId } = await fetchBedInformation(bedId);
        let requestTypes = await fetchRequestTypes(departmentId);

        setRequestTypes(
          requestTypes.map((requestType) => {
            return {
              id: requestType.id,
              requestType: requestType.name,
              detailMisc: requestType.detailMisc,
            };
          })
        );
      }
    };

    getAvailableRequestTypes();
  }, []);

  const getRequests = async () => {
    const patientId = await AsyncStorage.getItem("patientId");
    if (patientId) {
      fetchRequests(patientId).then((requests) => {
        setRequests(requests.map((r: Request) => r.requestType));
      });
    }
  };

  useEffect(() => {
    getRequests();
    // const intervalId = setInterval(getRequests, 5000); // Fetch every 5 seconds
    // return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  const handleConfirm = async () => {
    setConfirmModalVisible(false);
    await getRequests();

    const departmentId = await AsyncStorage.getItem("departmentId");
    const patientId = await AsyncStorage.getItem("patientId");

    if (departmentId && patientId && !requests.includes(selectedRequest)) {
      const response = await createRequest(
        departmentId,
        selectedRequest,
        miscData,
        patientId
      );
      if (response.status === 201 || response.status === 200) {
        Toast.show({
          type: "success",
          text1: `${t("Successfully requested")} ${t(selectedRequest)}`,
          text1Style: {
            fontSize: 15,
            fontWeight: "normal",
          },
        });
        setMiscData("");
      } else if (response.status === 403) {
        console.log("Patient has been discharged");
        setCloseAppModalVisible(true);
      }
    } else {
      Toast.show({
        type: "error",
        text1: `${t("You have already requested")} ${t(selectedRequest)}`,
        text1Style: {
          fontSize: 15,
          fontWeight: "normal",
        },
      });
    }
  };

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const { height, width } = useWindowDimensions();

  const Item = useCallback(
    ({ requestType, isHorizontal, isLast }: BoxItemProps) => (
      <TouchableOpacity
        onPress={async () => {
          setConfirmModalVisible(true);
          setSelectedRequest(requestType);
          await getRequests();
        }}
        className={`bg-[#ffffff] m-[2%] justify-center items-center border-black rounded-[20px] aspect-square flex-1 ${
          isLast && (isHorizontal ? "max-w-[21%]" : "max-w-[46%]") // a little less than 25 and 50 percent to account for gap between items
        }`}
      >
        <Image
          className="h-[60%] aspect-square self-center"
          source={require(`../assets/${RequestTypeToImage[requestType]}.png`)}
        />
        <Text className="text-xl md:text-2xl lg:text-3xl xl:text-4xl items-center text-center text-black pt-2">
          {t(requestType)}
        </Text>
      </TouchableOpacity>
    ),
    []
  );

  return (
    <SafeAreaView
      className="bg-[#ADCDF6] max-w-screen"
      style={{ height: "100%", maxHeight: "100dvh" }}
    >
      <View className="flex flex-row justify-between items-center py-2 px-4 bg-[#FFFFFB]">
        <Image
          className="w-[35px] h-[35px]"
          source={require("../assets/logo.png")}
        />
        <Text className="text-black text-lg">SJHCG</Text>
      </View>

      <TouchableOpacity
        className="bg-[#FFFFFB] rounded-[25px] items-center justify-center border-2 border-black m-4"
        onPress={navigateToViewRequest}
      >
        <Text className="text-black text-2xl lg:text-[30px] py-2">
          {t("View My Requests")}
        </Text>
      </TouchableOpacity>

      <View className="flex-1 overflow-y-scroll">
        {height > width ? (
          <FlatList
            key="vertical"
            data={requestTypes}
            numColumns={2}
            renderItem={({ item, index }) => (
              <Item
                requestType={item.requestType}
                isLast={index + 1 > 2 * Math.floor(requestTypes.length / 2)}
              />
            )}
          />
        ) : (
          <FlatList
            key="horizontal"
            data={requestTypes}
            numColumns={4}
            renderItem={({ item, index }) => (
              <Item
                requestType={item.requestType}
                isHorizontal
                isLast={index + 1 > 4 * Math.floor(requestTypes.length / 4)}
              />
            )}
          />
        )}
      </View>

      <View className="px-4 py-3 flex flex-row justify-between bg-[#FFFFFB] w-full items-center">
        <Text className="font-bold text-xl text-black">{qrCodeData}</Text>
        <Dropdown
          data={languages}
          labelField="key"
          valueField="value"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.value)}
          renderLeftIcon={() => (
            <Image
              className="w-4 h-4 mr-2"
              source={require("../assets/globe.png")}
            />
          )}
          style={{
            height: 40,
            borderColor: "gray",
            borderWidth: 0.5,
            borderRadius: 8,
            paddingHorizontal: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          // className="flex-1"
        />
      </View>

      <Modal
        visible={confirmModalVisible}
        animationType="slide"
        transparent
        onRequestClose={() => setConfirmModalVisible(false)}
        statusBarTranslucent
      >
        <View className="flex-1 justify-center items-center align-middle">
          <View className="bg-blue-50 border-2 p-[20px] w-[85%] h-[80%] align-middle items-center justify-center rounded-[10px]">
            <Text className="text-black pb-2 text-[20px] text-center">
              {t("Would you like to make a request for:")} {t(selectedRequest)}
            </Text>
            {selectedRequest === "Misc Assistance" && (
              <TextInput
                className="border-2 border-gray-300 rounded-md p-2 content-center mt-5 justify-center text-gray-500"
                placeholder={t("Enter additional details")}
                onChangeText={(text) => setMiscData(text)}
                value={miscData}
              />
            )}
            <View className="justify-center items-center pt-10">
              <View className="flex-row space-x-20">
                <TouchableOpacity
                  className="items-center rounded-md"
                  onPress={async () => {
                    setConfirmModalVisible(false);
                    await getRequests();
                  }}
                >
                  <Image
                    className="w-[40px] h-[40px]"
                    source={require("../assets/cancel.png")}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  className="items-center rounded-md"
                  onPress={handleConfirm}
                >
                  <Image
                    className="w-[40px] h-[40px]"
                    source={require("../assets/checked.png")}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="slide"
        visible={closeAppModalVisible}
        transparent={true}
      >
        <View className="flex-1 justify-center items-center align-middle">
          <View className="bg-blue-50 border-2 p-[20px] w-[85%] h-[80%] align-middle items-center justify-center rounded-[10px]">
            <Text className="text-black pb-20 text-[20px] text-center">
              {t("You have been discharged. Please close the tab.")}
            </Text>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

export default HomePage;
